<template>
  <div class="cargo-detail">
    <div class="cargo-tracking__header-panel">
      <van-image
        v-if="entity && entity.label"
        class="label-image"
        :src="entity.label"
        radius="5"
        @click="imagePreview"
        fit="cover"
      />
      <van-image
        v-else
        class="label-image"
        fit="contain"
        :src="require('@/assets/icons/send-inactive.png')"
      />
      <div>
        <div class="cargo-tracking__header-row">
          <span class="cargo-header__strong"
            >{{ $t("单号") }}：{{ entity ? entity.trackingNo : "" }}</span
          >
        </div>
        <div>
          <div v-if="entity">{{ entity.remark ? entity.remark : "" }}</div>
        </div>
        <div style="margin-top: 3px">
          <van-tag v-if="entity" size="medium" plain round color="#f2826a">{{
            $t(convertPaymentStatus(entity.paymentStatus))
          }}</van-tag>
        </div>
      </div>
    </div>
    <div v-if="entity && entity.status < 2 && steps.length <= 1">
      <van-cell
        v-if="entity.fServiceAmount"
        :value="entity.fServiceAmount + $t('铢')"
        :title="$t('增值服务')"
        is-link
        :to="{ name: 'followup', query: { cargoIds: entity.id } }"
      />

      <div style="background: #ffffff; padding: 20px; text-align: center">
        {{ $t("等待包裹入库") }}
      </div>
    </div>

    <div v-else-if="entity">
      <div class="panel">
        <van-cell
          :title="$t('重量')"
          :value="$t('weight 公斤', { weight: entity.weight })"
        />
        <van-cell
          :title="$t('长宽高(单位公分)')"
          :value="entity.length + ' x ' + entity.width + ' x ' + entity.height"
        />
        <van-cell
          :title="$t('体积')"
          :value="
            (entity.length * entity.width * entity.height) / 1000000 + 'm³'
          "
        />
        <van-cell
          :title="$t('运输方式')"
          :value="$t(convertExpressWay(entity.expressWay))"
        ></van-cell>
        <van-cell
          :title="$t('计费方式')"
          v-if="entity.billingMethod"
          :value="$t(convertBillingMethod(entity.billingMethod))"
        ></van-cell>
        <div v-if="entity.discount > 0 && entity.discount < 1">
          <van-cell
            :title="$t('折扣')"
            :value="entity.discount * 10 + $t('折')"
          />
          <van-cell :title="$t('转运费')">
            <div style="display: flex; justify-content: flex-end">
              <div style="text-decoration: line-through; margin-right: 10px">
                {{ entity.totalAmont }} {{ $t("铢") }}
              </div>
              <div>{{ entity.totalDiscountedAmont }} {{ $t("铢") }}</div>
            </div>
          </van-cell>
        </div>
        <van-cell
          v-else
          :title="$t('转运费')"
          :value="(entity.totalAmont ? entity.totalAmont : '') + $t('铢')"
        />
        <van-cell
          v-if="entity.fServiceAmount"
          :value="entity.fServiceAmount + $t('铢')"
          :title="$t('增值服务')"
          is-link
          :to="{
            name: 'followup',
            query: {
              cargoIds: entity.id,
            },
          }"
        />
      </div>
      <div class="panel no-average" style="display: none">
        <van-cell-group>
          <van-cell
            :title="$t('收件人')"
            size="small"
            :value="entity.receiverName + '/' + entity.receiverMobile"
          />
          <van-cell
            :title="$t('收件地址')"
            size="small"
            :value="entity.receiverAddress + ' ' + entity.receiverPostalCode"
          />
        </van-cell-group>
      </div>
      <van-steps desc-class="vant-step-desc" direction="vertical">
        <van-step v-for="step in steps" :key="step.id">
          <span style="font-size: 1rem">{{ $t(step.text) }}</span>
          <br />
          <span style="display: inline-block">{{ step.desc }}</span>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import {
  Image as VanImage,
  Tag,
  Cell,
  CellGroup,
  Step,
  Steps,
  Toast,
  ImagePreview,
} from "vant";
import {
  convertPaymentStatus,
  convertExpressWay,
  convertBillingMethod,
} from "@/utils/dict";
import { formatTime } from "@/utils/utils";

import { cargoDetail } from "@/service/api";
import { getLocale } from "@/utils/storage";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Tag.name]: Tag,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Step.name]: Step,
    [Steps.name]: Steps,
  },
  data() {
    return {
      id: this.$route.query.id,
      entity: null,
      steps: [],
    };
  },
  mounted() {
    this.cargoDetail();
  },
  methods: {
    setRemark() {
      this.$router.push({
        name: "remark",
        query: { id: this.entity.id, remark: this.entity.remark },
      });
    },
    imagePreview() {
      ImagePreview([this.entity.label]);
    },
    copyTrackingNo() {
      let oInput = document.createElement("input");
      oInput.value = this.entity.trackingNo;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
    convertPaymentStatus(value) {
      return convertPaymentStatus(value);
    },
    convertExpressWay(value) {
      return convertExpressWay(value);
    },
    convertBillingMethod(value) {
      return convertBillingMethod(value);
    },
    async cargoDetail() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await cargoDetail(this.id);
      this.entity = res;

      let steps = [];

      const locale = getLocale();

      steps = res.routes.map((item) => {
          let text;

          switch (locale) {
            case "zh-TW":
              text = item.title ? item.title : item.description;
              break;
            case "th-TH":
              text = item.localTitle ? item.localTitle : item.localDescription;
              break;
            default:
              text = item.enTitle ? item.enTitle : item.enDescription;
              break;
          }

          text = text ? text : item.enTitle ? item.enTitle : item.enDescription;
          return {
            text: text,
            desc: formatTime(item.createdAt),
          };
        });

      this.steps = steps;

      Toast.clear();
    },
  },
};
</script>
<style lang="less" scoped>
.cargo-tracking__header-panel {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 5px;
  display: flex;
}

.cargo-tracking__header-panel .label-image {
  width: 125px;
  height: 100px;
  border-radius: 5px;
  margin-right: 10px;
}

.cargo-tracking__header-row {
  /* display: flex; */
  margin-bottom: 0px;
}
.cargo-tracking__header-label {
  font-size: 14px;
}
.cargo-tracking__header-value {
  font-size: 14px;
}
.cargo-tracking__header-copy {
  font-size: 14px;
  margin-right: 15px;
  color: #569cd6;
}

.cargo-header__strong {
  font-size: 20px;
}

.cargo-header__secondary {
  color: #868e96;
}

.amount {
  color: red;
  font-size: 18px;
}

.van-step__title {
  font-size: 16px !important;
}
.no-average {
  .van-cell__value {
    min-width: 70%;
  }
}
</style>